<template>
  <div
    v-if="count && length && count > length"
    :style="{ 'text-align': right ? 'right' : 'left' }"
    style="padding: 5px 10px;"
  >
    <a
      :style="{ margin: `0 20px 0 ${margin}px !important` }"
      @click="onShowMore"
      class="show-more"
    >
    <span style="font-size: 0.7rem">({{ length }} {{ $t('common.of') }} {{ count }})</span> 
    <v-icon>mdi-chevron-down</v-icon>
    </a>
  </div>
</template>
<script>
export default {
  name: 'ShowMore',
  props: {
    count: {
      type: Number,
      default: null,
    },
    length: {
      type: Number,
      default: null,
    },
    right: {
      type: Boolean,
      default: false
    },
    level: {
      type: Number,
      default: null
    },
    onShowMore: {
      type: Function,
      default: null,
    },
  },
  data: () => ({
    margin: 0,
  }),
  mounted () {
    this.margin = 20 * this.level
  },
}
</script>
<style scoped>
.show-more {
  color: rgba(0, 0, 0, 0.54);
}
</style>

